import React, { useEffect, useState, useRef } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { useDispatch, useSelector } from 'react-redux';
import { useTransition, animated } from 'react-spring';
import {
  Switch, Route, useLocation, useHistory,
} from 'react-router-dom';

import { SET_STEP, SET_MEDIA, SET_QUESTION } from './actions/types';
import { getSessionAction } from './actions/quizActions';
import setVhUnit, { getMediaType } from './utils/utils';
import Home from './pages/Home/Home';
import Serie from './pages/Serie/Serie';
import Footer from './components/Footer/Footer';
import Stop from './pages/Stop/Stop';
import End from './pages/End/End';
import Media from './components/Media/Media';
import Results from './pages/Results/Results';

import './styles/index.scss';

// TIME BEFORE TRYING TO ATTEMPTED A NEW CONNECTION;
const timeout = 500;

function App() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const socketRef = useRef();
  const { token, series, step } = useSelector((state) => state.quiz);
  const transitions = useTransition(location, {
    key: location.pathname,
    // from: { opacity: 1, transform: 'translate3d(0,-100%,0)' },
    // enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    // leave: { opacity: 1, transform: 'translate3d(0,100%,0)' },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const [isMuted, setIsMuted] = useState(true);

  // const [index, setIndex] = useState(0);

  // const steps = [
  //   {
  //     type: 'start',
  //   },
  //   {
  //     type: 'serie',
  //     serie: 1845,
  //   },
  //   {
  //     type: 'serie',
  //     serie: 1845,
  //     question: 169,
  //     src: 'audio',
  //   },
  //   {
  //     type: 'stop',
  //     serie: 1846,
  //     // next serie
  //   },
  //   {
  //     type: 'serie',
  //     serie: 1847,
  //   },
  //   {
  //     type: 'serie',
  //     serie: 1845,
  //   },
  //   {
  //     type: 'results',
  //   },
  //   {
  //     type: 'end',
  //   },
  // ];

  // console.log(JSON.stringify(steps));

  // function fakeSockets(a) {
  //   let media = null;

  //   const mediaFound = series?.find(
  //     (s) => s.id === steps[a]?.serie,
  //   )?.questions?.find(
  //     (q) => q.id === steps[a]?.question,
  //   )?.lien_support;

  //   if (mediaFound) {
  //     media = {
  //       src: mediaFound,
  //       type: steps[a].src,
  //       legend: series?.find(
  //         (s) => s.id === steps[a].serie,
  //       )?.questions?.find((q) => q.id === steps[a]?.question),
  //     };
  //   }
  //   dispatch({
  //     type: SET_STEP,
  //     payload: {
  //       type: steps[a]?.type,
  //       data: series?.find((s) => s.id === steps[a].serie),
  //       media,
  //     },
  //   });
  // }

  // useEffect(() => {
  //   if (series) {
  //     let i = 0;

  //     setTimeout(() => {
  //       fakeSockets(i);
  //       i++;
  //     }, 2000);
  //   }
  // }, [series]);

  useEffect(() => {
    setVhUnit();
  }, []);

  useBeforeunload((event) => {
    if (socketRef?.current) {
      event.preventDefault();
      socketRef?.current?.send(JSON.stringify({
        meta: 'leave',
      }));
      socketRef?.current?.close();
    }
  });

  function handleWebSocket() {
    socketRef.current = new WebSocket(`wss://ws.dev.cotton-quiz.com/?token=${token}`);
    socketRef.current.onopen = () => {
      console.log('WebSocket is opened.');
      socketRef.current.send(JSON.stringify({
        meta: 'join',
      }));
    };
    socketRef.current.onmessage = (message) => {
      let media = null;
      const stepData = JSON.parse(message.data);
      if (stepData.action === 'fetch_session') {
        getSessionAction(dispatch);
      } else if (stepData.action === 'stop_media') {
        dispatch({
          type: SET_MEDIA,
          payload: null,
        });
      } else if (stepData.action === 'change_question') {
        const { question, serie, bonus } = stepData;
        dispatch({
          type: SET_QUESTION,
          payload: {
            path: 'serie',
            question,
            bonus,
            data: series?.find((s) => s.id === serie),
          },
        });
      } else if (stepData.action === 'launch_media') {
        const question = series?.find(
          (s) => s.id === stepData?.serie,
        )?.questions?.find(
          (q) => q.id === stepData?.question,
        );

        const mediaFound = stepData.answer ? (
          question?.lien_support_reponse
        ) : question?.lien_support;

        let typeSupport = null;
        if (stepData.answer) {
          typeSupport = getMediaType(question?.id_type_support_reponse);
        } else {
          typeSupport = getMediaType(question?.id_type_support);
        }
        if (mediaFound) {
          media = {
            src: mediaFound,
            type: stepData.src,
            typeSupport,
            legend: question,
            answer: stepData.answer,
            step: { numero: series?.find((s) => s.id === stepData.serie)?.numero },
          };

          dispatch({
            type: SET_MEDIA,
            payload: media,
          });
        }
      } else if (!stepData.action) {
        const { question, path, serie } = stepData;
        dispatch({
          type: SET_STEP,
          payload: {
            path,
            question,
            data: series?.find((s) => s.id === serie),
          },
        });
      }
    };

    socketRef.current.onerror = (event) => {
      console.error("WebSocket error observed:", event);
    };

    socketRef.current.onclose = (event) => {
      console.log(
        `Socket is closed. Reconnect will be attempted in ${timeout / 1000} second.`,
        event.reason,
      );

      function check() {
        console.log('wsClient', socketRef.current.readyState, WebSocket.CLOSED);
        if (!socketRef.current || socketRef.current.readyState === WebSocket.CLOSED) {
          handleWebSocket();
        }
      }
      setTimeout(check, timeout);
    };
    socketRef.current.onerror = (event) => {
      console.error('WebSocket error observed:', event);
    };
  }

  useEffect(() => {
    if (token && series) {
      handleWebSocket();
    }
  }, [token, series]);

  // useEffect(() => {
  //   if (token && series && index && !isMuted) {
  //     fakeSockets(index - 1);
  //   }
  // }, [token, index, series]);

  useEffect(() => {
    if (!token) return;
    if (step?.path) {
      history.push(`/${step.path}/${token}`);
    }
  }, [step?.path, history, token]);

  return (
    <div className="app-container">
      <div className="page-container">
        {transitions((props, item, t, key) => (
          <animated.div key={key} style={props}>
            <Switch location={item}>
              <Route exact path={'/start/:token'} render={
                () => <Home />
              } />
              <Route exact path={'/serie/:token'} render={
                () => <Serie />
              } />
              <Route exact path={'/stop/:token'} render={
                () => <Stop />
              } />
              <Route exact path={'/results/:token'} render={
                () => <Results />
              } />
              <Route exact path={'/end/:token'} render={
                () => <End />
              } />
            </Switch>
          </animated.div>
        ))}
        <Media
          isMuted={isMuted}
          setIsMuted={setIsMuted}
        />
        <Footer
        />
      </div>
    </div>
  );
}

export default App;
